$leafColour: #87c0d2;

// Shades
// #4fa3bd
// #62adc4
// #74b6cb
//// #87c0d2
// #9acad9
// #acd3e0
// #bfdde7

body {
  background-color: #fcfcfc
}

svg {
  color: $leafColour;
}

a {
  svg {
    margin: 0 10px;
    filter: brightness(0.95);

    &:hover {
      filter: brightness(1);
    }
  }
}

.me {
  display: block;
  border-radius: 50%;
  max-width: 200px;
  // box-shadow: 0 0 8px 3px #f0f0f0;
}
