.sakura {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  pointer-events: none;
}

.sakura .leaf-scene {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.sakura .leaf-scene div {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background: url(./sakura-petal-blue.png) no-repeat;
  background-size: 100%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  /* filter: hue-rotate(180deg); */
}
