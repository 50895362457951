.vr {
  flex-basis: 2px;
  border: none;
  width: 4px;
  margin: 0 2.8rem;
  background-image: linear-gradient(
    to bottom,
    rgba(235, 235, 235, 0),
    rgba(235, 235, 235, 1),
    rgba(235, 235, 235, 1),
    rgba(235, 235, 235, 1),
    rgba(235, 235, 235, 0)
  );
}
